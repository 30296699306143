<template>
  <u-table
      ref="plTable"
      :data="tableData"
      :height="height"
      use-virtual
      showBodyOverFlow="title"
      showHeaderOverflow="title"
      :row-height="rowHeight"
      v-on="$listeners"
      v-bind="$attrs"
      border
  >
    <template v-for="(item,index) in columns">
      <u-table-column type="index" width="60" v-if="item.type === 'index'"/>
      <u-table-column type="selection" width="55" v-else-if="item.type === 'selection'"/>
      <u-table-column
          v-else
          v-for="(item,index) in columns"
          :key="item.prop + index"
          :show-overflow-tooltip="true"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
      >
        <!--      使用动态插槽-->
        <template v-slot="{ row }">
          <slot :name="item.prop" :row="row">{{ row[item.prop] }}</slot>
        </template>
      </u-table-column>
    </template>

<!--    固定列-->

<!--    动态列-->

  </u-table>
</template>

<script >
  export default {
    name : "FUTable",
    props : {
      tableData: {
        type : Array,
        default : () => []
      },
      columns: {
        type : Array,
        default : () => []
      },
      height : {
        type : [String, Number],
        default : 500
      },
      rowHeight : {
        type : [String, Number],
        default : 50
      }
    }
  }
</script>



<style scoped lang="scss">

</style>